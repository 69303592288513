import React, { useRef, useState, useMemo } from 'react';
import { Base as DataTable } from '@reusejs/react-tables';
import { DataLoadOrchestrator } from '@reusejs/react-utils';
import { Loader } from '@reusejs/react-loaders';
import { BlueButton } from '@reusejs/react-buttons';
import { Simple } from '@reusejs/react-modals';
import PopupForm from './PopupForm';
import DeletePopUp from './DeletePopUp';
import CreatePopUp from './CreatePopUp';
import SectionLoader from '../components/reusables/SectionLoader';
import EmptyState from '../components/reusables/EmptyState';
import {
  getAllTradesOfTheDay,
  capitalizeFirstLetter,
} from '@nx-monorepo/services';
import formatDate from '../utils/formatDate';

const openModal = async (item) => {
  await Simple({
    ModalContent: item,
  });
};

const Data = ({ response, submitOnRefresh = () => {} }) => {
  const tableRef = useRef(null);

  const fetchProfiles = () => {
    try {
      return response.data;
    } catch (error) {
      return error;
    }
  };

  const config = {
    tableWrapperClasses: 'overflow-hidden mx-5 border rounded-lg',
    tableClasses: 'min-w-full divide-y divide-white px-5',
    headerClasses: 'relative py-4 px-5',
    rowEvenClasses: 'bg-gray-100',
    filterable: false,
    columns: [
      {
        label: 'Currency Pair',
        identifier: 'currency_pair',
        resolver: (d) => {
          return (
            <div
              onClick={() => {
                return;
              }}
            >
              {d?.currency_pair ? d?.currency_pair : 'N/A'}
            </div>
          );
        },
      },
      {
        label: 'Buy/Sell',
        identifier: 'type',
        resolver: (d) => {
          return (
            <div className="flex justify-between">
              <label className="font-semibold">{d.type_value || '-'}</label>

              <label
                className={
                  d?.type === 'buy'
                    ? 'w-fit rounded-md bg-green-700 px-2 py-0.5 text-center font-semibold text-white'
                    : 'w-fit rounded-md bg-yellow-700 px-2 py-0.5 text-center font-semibold text-white'
                }
              >
                {capitalizeFirstLetter(d?.type) || 'N/A'}
              </label>
            </div>
          );
        },
      },
      {
        label: 'Take Profit',
        identifier: 'take_profit_value',
        resolver: (d) => {
          return (
            <div className="flex flex-col">
              <label className="font-semibold">
                {d?.take_profit_value || 'N/A'}
              </label>
            </div>
          );
        },
      },
      {
        label: 'Stop Loss',
        identifier: 'stop_loss_value',
        resolver: (d) => {
          return (
            <div className="flex flex-col">
              <label className="font-semibold">
                {d?.stop_loss_value || 'N/A'}
              </label>
            </div>
          );
        },
      },
      {
        label: 'Action',
        identifier: '',
        resolver: (d) => {
          return (
            <div className="flex justify-center gap-x-2">
              <BlueButton
                type="button"
                widthClasses="w-32"
                fontClasses="text-sm font-semibold"
                borderClasses="rounded-lg"
                busyText="Processing"
                onClick={() =>
                  openModal(
                    React.forwardRef((props, ref) => {
                      return (
                        <div
                          ref={ref}
                          className="flex h-screen w-full items-center justify-center"
                        >
                          <PopupForm
                            submitOnRefresh={(cond) => submitOnRefresh(cond)}
                            data={d}
                            {...props}
                          />
                        </div>
                      );
                    })
                  )
                }
              >
                Update
              </BlueButton>
              <BlueButton
                type="button"
                widthClasses="w-32"
                fontClasses="text-sm font-semibold"
                borderClasses="rounded-lg"
                busyText="Processing"
                onClick={() =>
                  openModal(
                    React.forwardRef((props, ref) => {
                      return (
                        <span
                          ref={ref}
                          className="flex h-screen w-full items-center justify-center"
                        >
                          <DeletePopUp
                            submitOnRefresh={(cond) => submitOnRefresh(cond)}
                            data={d}
                            {...props}
                          />
                        </span>
                      );
                    })
                  )
                }
              >
                Delete
              </BlueButton>
            </div>
          );
        },
        sortable: false,
      },
    ],
  };

  return (
    <div className="mt-5">
      <DataTable
        ref={tableRef}
        config={config}
        defaultSortColumn={0}
        perPage={5}
        dataSource={async (params) => {
          try {
            let response = await fetchProfiles(params);
            response = response.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            return {
              data: response,
              pagination: {
                total: 0,
              },
            };
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </div>
  );
};

const ErrorState = ({ response }) => {
  return (
    <div className="flex h-96 items-center justify-center">
      {response.data ? response.data : 'Something went wrong!'}
    </div>
  );
};

export default function ({ mode, callAction, filterBy, submitOnRefresh }) {
  const orchestratorRef = useRef(null);
  const [reload, setReload] = useState(false);

  const handler = async () => {
    try {
      let result = await getAllTradesOfTheDay({});
      result = result.data;

      if (Object.keys(result).length === 0) {
        return {
          state: 'empty',
          data: result,
        };
      }

      return {
        state: 'data',
        data: result,
      };
    } catch (error) {
      return {
        state: 'error',
        data: 'Something went wrong',
      };
    }
  };

  useMemo(() => {
    if (reload || submitOnRefresh) {
      orchestratorRef.current.reload();
    }
  }, [reload, submitOnRefresh]);

  const emptyConfigHeaderCols = [
    {
      label: 'Currency Pair',
      identifier: 'currency_pair',
      resolver: () => {},
    },
    {
      label: 'Buy/Sell',
      identifier: 'type',
      resolver: () => {},
    },
    {
      label: 'Take Profit',
      identifier: 'take_profit_value',
      resolver: () => {},
    },
    {
      label: 'Stop Loss',
      identifier: 'stop_loss_value',
      resolver: () => {},
    },
    {
      label: 'Action',
      identifier: '',
      resolver: () => {},
    },
  ];

  return (
    <>
      <div className="ml-3 flex w-44 cursor-pointer py-4 hover:text-blue-600">
        <BlueButton
          widthClasses="w-40"
          fontClasses="text-sm font-semibold"
          borderClasses="rounded-lg"
          paddingClasses="px-4 py-3"
          busyText="Processing"
          onClick={() =>
            openModal(
              React.forwardRef((props, ref) => {
                return (
                  <span
                    ref={ref}
                    className="flex h-screen w-full items-center justify-center"
                  >
                    <CreatePopUp
                      submitOnRefresh={(cond) => setReload(cond)}
                      {...props}
                    />
                  </span>
                );
              })
            )
          }
        >
          + Trade of the Day
        </BlueButton>
      </div>
      {/* Added button */}
      <DataLoadOrchestrator
        handler={handler}
        ref={orchestratorRef}
        components={{
          data: React.forwardRef((props, ref) => (
            <span ref={ref}>
              <Data
                mode={mode}
                callAction={callAction}
                filterBy={filterBy}
                submitOnRefresh={(cond) => setReload(cond)}
                {...props}
              />
            </span>
          )),
          loading: SectionLoader,
          empty: React.forwardRef((props, ref) => (
            <>
              <span ref={ref}>
                <EmptyState
                  tableHeadColumns={emptyConfigHeaderCols}
                  {...props}
                />
              </span>
            </>
          )),
          error: ErrorState,
        }}
      />
    </>
  );
}
